import PageHeader from "../../compoenents/page-header/page-header.component";

const Account = () => {
    return (
        <div className="container">
            <PageHeader title="Account" />
        </div>
    )
}

export default Account;