import PageHeader from "../../compoenents/page-header/page-header.component";

const User = () => {
    return (
        <div className="container">
            <PageHeader title="Users" />
        </div>
    )
}

export default User;