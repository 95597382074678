import PageHeader from "../../compoenents/page-header/page-header.component";

const Home = () => {
    return (
        <div className="container">
            <PageHeader title={"Overview"}/>
        </div>
    )
}

export default Home;